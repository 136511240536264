import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {currency, isoDateToDisplayedDate, isoDateToDisplayedDateTime} from 'utils/format';
import Button from 'components/button/button';
const Root = styled.table.attrs({className: 'table'})``;
export const InputTr = styled.tr`
  border-style: dotted;

  .form-group {
    margin: 0;
  }

  .custom-checkbox {
    padding-top: 10px;
    margin: 0;
  }
`;
export const Td = ({value, format, children}) => {
  if (children) {
    return <td>{children}</td>;
  }

  if (!format) {
    return <td>{value}</td>;
  }
  let data;

  switch (format) {
    case 'date':
      data = isoDateToDisplayedDate(value);
      break;
    case 'datetime':
      data = isoDateToDisplayedDateTime(value);
      break;
    case 'currency':
      data = currency(value);
      break;
    case 'array:comma':
      data = value.join(', ');
      break;
    default:
      data = value;
      break;
  }

  return <td>{data}</td>;
};

Td.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.oneOf(['date', 'currency']),
};

export const Thead = ({thead}) => {
  if (!(thead && thead.length)) {
    return null;
  }

  return (
    <thead>
      <tr>
        {map(thead, item => {
          if (typeof item === 'string') {
            return <th key={item}>{item}</th>;
          }

          const {label} = item;
          return <th key={label}>{label}</th>;
        })}
      </tr>
    </thead>
  );
};

Thead.propTypes = {
  thead: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        sort: PropTypes.string,
      }),
    ])
  ),
};

const TableDeleteActions = ({undoDelete, confirmDelete, deletedRowData}) => {
  if (!(deletedRowData && deletedRowData.length)) {
    return null;
  }
  const changes = deletedRowData.length;
  const s = changes > 1 ? 's' : '';
  return (
    <Fragment>
      <div className="alert alert-warning">{`${changes} unconfirmed deletion${s}`}</div>
      <div className="float-right">
        <Button primary marginRight handleClick={undoDelete}>
          {`Undo Deletion${s}`}
        </Button>
        <Button danger handleClick={confirmDelete}>
          {`Confirm Deletion${s}`}
        </Button>
      </div>
    </Fragment>
  );
};

TableDeleteActions.propTypes = {
  deletedRowData: PropTypes.arrayOf(PropTypes.object),
  confirmDelete: PropTypes.func,
  undoDelete: PropTypes.func,
};

const Table = props => {
  const {className, thead, rowData, rowRenderer, deletable, children} = props;

  return (
    <Fragment>
      <Root className={className}>
        <Thead thead={thead} />
        <tbody>
          {map(rowData, rowRenderer)}
          {children}
        </tbody>
      </Root>
      {deletable && <TableDeleteActions {...deletable} />}
    </Fragment>
  );
};

Table.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tbody: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowRenderer: PropTypes.func.isRequired,
  deletable: PropTypes.shape({
    deletedRowData: PropTypes.arrayOf(PropTypes.object),
    confirmDelete: PropTypes.func,
    undoDelete: PropTypes.func,
  }),
  thead: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        sort: PropTypes.string,
      }),
    ])
  ),
};

export default Table;
