import React from 'react';
import {Router} from '@reach/router';
import PrivateRoute from 'components/app/private-route';
import Dashboard from 'components/app/dashboard/dashboard';
import Test from 'components/app/test';
import Help from 'components/app/help';
import Login from 'components/app/login';
import DividendVoucher from 'components/app/dividend_voucher/dividend-voucher';
import TakeHomePayCalculator from 'components/app/take-home-pay-calculator';
import Pomodoro from 'components/app/pomodoro/pomodoro';
import Profile from 'components/app/profile/profile';
//import PublicRoute from 'app/public-route';
import {basePath, appRoutes} from 'utils/constant';
import PropTypes from 'prop-types';

const App = ({location}) => (
  <Router basepath={basePath}>
    <PrivateRoute location={location} path={appRoutes.dashboard} component={Dashboard} />
    <PrivateRoute location={location} path={appRoutes.help} component={Help} />
    <PrivateRoute location={location} path={appRoutes.test} component={Test} />
    <PrivateRoute location={location} path={appRoutes.profile} component={Profile} />
    <PrivateRoute
      location={location}
      path={appRoutes.dividendVoucher}
      component={DividendVoucher}
    />
    <PrivateRoute
      location={location}
      path={appRoutes.takeHomePayCalculator}
      component={TakeHomePayCalculator}
    />
    <PrivateRoute
      location={location}
      path={appRoutes.pomodoroTimer}
      component={Pomodoro}
    />
    <Login path="/login" />
  </Router>
);

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default App;
