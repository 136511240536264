import {formatDefaultLocale} from 'd3-format';
import {timeFormatLocale} from 'd3-time-format';
import moment from 'moment';

const enGb = {
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['£', ''],
  dateTime: '%a %e %b %X %Y',
  date: '%d/%m/%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

const d3Format = formatDefaultLocale(enGb);
const d3TimeFormat = timeFormatLocale(enGb);

export const currency = d3Format.format('$,.2f');
export const displayedDateTime = d3TimeFormat.format('%a %e %b %X %Y');
export const displayedDate = d3TimeFormat.format('%d %B, %Y');
export const isoDateToDisplayedDate = isoDateString =>
  displayedDate(moment(isoDateString));
export const isoDateToDisplayedDateTime = isoDateTimeString =>
  displayedDateTime(moment(isoDateTimeString));
