import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import UserMenu from 'components/nav/usermenu';
import styled, {css} from 'styled-components';

const Root = styled.header`
  padding: 0 18px;
  height: ${props => props.theme.nav.height};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 995;
  transition: margin-left 0.3s ease;
  background-color: #fff;
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.margin &&
    css`
      margin-left: 260px;
    `}

  ${({to, selected}) =>
    to &&
    selected &&
    css`
      border-top-color: ${props => props.theme.colors.link};
    `};
`;
const NavLeft = styled.div`
  display: flex;
  align-items: center;
`;
const NavRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;
const NavDivider = styled.div`
  border-left: 1px solid rgba(77, 82, 89, 0.07);
  height: 20px;
  align-self: center;
  margin: 0 12px;
`;
const NavButtons = styled.ul`
  max-height: ${props => props.theme.nav.height};
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row-reverse;
`;

export const NavButton = styled(Button)`
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  background: #fff;
  height: ${props => props.theme.nav.height};
  line-height: ${props => props.theme.nav.height};
  padding: 0 12px;
  text-align: center;
  color: rgba(77, 82, 89, 0.7);
  cursor: pointer;
  min-width: 48px;
  white-space: nowrap;
  border: none;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transition: 0.2s linear;
  position: relative;
  z-index: 991;

  &:focus,
  &:hover {
    outline: 0;
    border-top-color: ${props => props.theme.colors.link};
  }

  ${props =>
    props.group &&
    css`
      font-size: 1.125rem;
      min-width: 48px;
      color: rgba(77, 82, 89, 0.4);
    `}
`;
const AppNavbar = ({sideBarVisible, toggleSideBar, sideBarToggleAlt}) => {
  const alt = `${sideBarToggleAlt} sidebar`;

  return (
    <Root margin={sideBarVisible} role="navigation">
      <NavLeft>
        <NavButton icon="menu" handleClick={toggleSideBar} ariaLabel={alt} title={alt} />
        <NavDivider className="d-none d-md-block" />
      </NavLeft>
      <NavRight>
        <NavButtons>
          <UserMenu />
        </NavButtons>
        <NavDivider />
      </NavRight>
    </Root>
  );
};

AppNavbar.propTypes = {
  sideBarVisible: PropTypes.bool.isRequired,
  toggleSideBar: PropTypes.func.isRequired,
  sideBarToggleAlt: PropTypes.string.isRequired,
};

export default AppNavbar;
