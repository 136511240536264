import React from 'react';
import {Card as Root, CardBody, CardTitleH2} from 'components/wrappers';
import Button from 'components/button/button';
import {useToggle} from 'utils/hooks';
import {ToggleIcon} from 'components/toggle';

const Card = ({title, children, className, collapsibe, collapsed}) => {
  if (!collapsibe) {
    return (
      <Root className={className}>
        <CardTitleH2>{title}</CardTitleH2>
        <CardBody>{children}</CardBody>
      </Root>
    );
  }
  const {visible, handleToggle, toggleAltContent} = useToggle(collapsed);

  return (
    <Root className={className}>
      <CardTitleH2>
        <Button anchor handleClick={handleToggle} title={toggleAltContent}>
          <ToggleIcon fontSize="22px" label={title} visible={visible} />
        </Button>
      </CardTitleH2>
      {visible && <CardBody>{children}</CardBody>}
    </Root>
  );
};

export default Card;
