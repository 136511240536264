import React from 'react';
import {navigate} from 'gatsby';
import auth from 'utils/auth';
import Input from 'components/form/input';
import CheckInput from 'components/form/check-input';
import Button from 'components/button/button';
import {IconFA} from 'components/icons';
import Copyright from 'components/copyright';
import {Form} from 'components/wrappers';
import {useInput, useCheck} from 'utils/hooks';

const Login = () => {
  if (auth.isAuthenticated()) {
    navigate('/app');
  }
  const email = useInput('jim@example.org');
  const password = useInput('demo');
  const remember = useCheck(false);

  return (
    <div className="row min-h-fullscreen center-vh p-20 m-0">
      <div className="col-12">
        <div
          className="card card-shadowed px-50 py-30 w-400px mx-auto"
          style={{maxWidth: '100%'}}
        >
          <h5 className="text-uppercase">Login</h5>
          <br />
          <Form
            method="post"
            onSubmit={event => {
              event.preventDefault();
              navigate('/app');
            }}
          >
            <Input id="email" type="email" label="Email" {...email} autoFocus />
            <Input id="password" type="password" label="Password" {...password} />
            <div className="form-group flexbox flex-column flex-md-row">
              <CheckInput id="remember" label="Remember me" {...remember} />
              <Button
                anchor
                className="text-muted hover-primary fs-13 mt-2 mt-md-0"
                href="#"
              >
                Forgot password?
              </Button>
            </div>

            <div className="form-group">
              <Button primary bold block type="submit">
                Login
              </Button>
            </div>
          </Form>

          <div className="divider">Or Login With</div>
          <div className="text-center">
            <a className="btn btn-square btn-facebook" href="#">
              <IconFA icon="faFacebookF" />
            </a>{' '}
            <a className="btn btn-square btn-google" href="#">
              <IconFA icon="faGoogle" />
            </a>{' '}
            <a className="btn btn-square btn-twitter" href="#">
              <IconFA icon="faTwitter" />
            </a>
          </div>
        </div>
        <p className="text-center text-muted fs-13 mt-20">
          Don&apos;t have an account?{' '}
          <Button anchor className="text-primary fw-500" href="#">
            Sign up
          </Button>
        </p>
      </div>

      <footer className="col-12 align-self-end text-center fs-13">
        <p className="mb-0">
          <Copyright small />
        </p>
      </footer>
    </div>
  );
};

export default Login;
