import React, {Fragment} from 'react';
import Card from 'components/blocks/card';
import DividendVouchersTable from 'components/app/dividend_voucher/dividend-vouchers-table';
import DividendVoucherForm from 'components/app/dividend_voucher/dividend-voucher-form';

const DividendVoucher = () => (
  <Fragment>
    <Card title="Dividends" collapsibe collapsed={false}>
      <p>A dividend is a share of the company profits paid out to shareholders.</p>
      <p>
        To pay a dividend, a board meeting must be held to <strong>declare</strong> the
        dividend, and minutes must be held of the meeting, even if you are the only
        director.
      </p>
      <p>
        For each dividend payment the company makes, a dividend voucher must be produced
        showing the following:
      </p>
      <ul>
        <li>date of when the dividend was declared</li>
        <li>company name</li>
        <li>name of the shareholder being paid the dividend</li>
        <li>dividend amount</li>
      </ul>
      <p>
        A voucher should be issued to each shareholder being paid a dividend, and a copy
        should be retained for company records.
      </p>
      <p>
        The following tool is used to produce the both the dividend vouchers as well as
        the board meeting minutes which show when the dividend was declared.
      </p>
    </Card>
    <DividendVoucherForm />
    <DividendVouchersTable />
  </Fragment>
);

DividendVoucher.layoutHeader = {
  h1: 'Dividend Voucher',
  intro: ['Generate dividend vouchers for your company.'],
};

export default DividendVoucher;
