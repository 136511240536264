import React, {Fragment} from 'react';
import {useQuery} from '@apollo/react-hooks';
import Card from 'components/blocks/card';
import {Row} from 'components/wrappers';
import Badges from 'components/blocks/badges';
import {QUERY_USER} from 'components/app/profile/profile-gql';
import AddressTable from 'components/app/profile/address-table';
import PersonalDetails from 'components/app/profile/personal-details';

const Profile = () => {
  const {loading, error, data} = useQuery(QUERY_USER);

  if (error) return <p>{error.message}</p>;
  if (loading) return <p>Loading</p>;

  const {addresses} = data.user;

  return (
    <Fragment>
      <Row>
        <div className="col-lg-5">
          <PersonalDetails />
        </div>
        <div className="col-lg-7">
          <AddressTable addresses={addresses} />
        </div>
      </Row>
    </Fragment>
  );
};

Profile.layoutHeader = {
  h1: 'Profile',
  intro: ['Set personal details here.'],
};

export default Profile;
