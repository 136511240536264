import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {useDeleteTableRows} from 'utils/hooks';
import {Form, Row} from 'components/wrappers';
import Card from 'components/blocks/card';
import Table, {InputTr, Td} from 'components/table';
import Button from 'components/button/button';
import {
  MUTATION_DELETE_ADDRESS,
  MUTATION_CREATE_ADDRESS,
  QUERY_USER_ADDRESSES,
  QUERY_USER,
} from 'components/app/profile/profile-gql';
import Input from 'components/formik/input';
import Checkbox from 'components/formik/check-input';
import {shape, stringValidator} from 'utils/validation';
import {actions} from 'state/notifications';

const validationSchema = shape({
  addressLine1: stringValidator('Address line 1'),
  townOrCity: stringValidator('Town/City'),
  postcode: stringValidator('Postcode'),
});
const initialFormValues = {
  addressLine1: '',
  addressLine2: '',
  townOrCity: '',
  county: '',
  postcode: '',
  billing: false,
};

const AddressTable = ({addNotification}) => {
  const {loading, error, data} = useQuery(QUERY_USER);

  if (error) return <p>{error.message}</p>;
  if (loading) return <p>Loading</p>;

  const {addresses} = data.user;

  const [deleteAddresses] = useMutation(MUTATION_DELETE_ADDRESS);
  const [createAddress] = useMutation(MUTATION_CREATE_ADDRESS);
  const {
    deletedRowIds,
    deleteRow,
    getVisibleAndDeletedRows,
    clearDeletedRows,
  } = useDeleteTableRows([]);

  if (!(addresses && addresses.length)) {
    return null;
  }

  const {deletedRows, visibleRows} = getVisibleAndDeletedRows(addresses);

  return (
    <Row>
      <div className="col-lg-12">
        <Formik
          initialValues={{...initialFormValues}}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const {setSubmitting, resetForm} = actions;

            setSubmitting(true);
            try {
              await createAddress({
                variables: {
                  addressCreateInput: {
                    ...values,
                  },
                },
                refetchQueries: [{query: QUERY_USER_ADDRESSES}],
              });
              addNotification({
                type: 'success',
                message: `Successfully added address: ${values.addressLine1}`,
              });
              setSubmitting(false);
              resetForm({...initialFormValues});
            } catch (ex) {
              addNotification({
                type: 'danger',
                message: ex.message,
              });
              setSubmitting(false);
            }
          }}
          validateOnBlur
        >
          {props => {
            const {values, handleSubmit, isSubmitting} = props;
            return (
              <Card title="My Addresses">
                <Form onSubmit={handleSubmit}>
                  <Table
                    className="table-bordered table-striped table-hover"
                    thead={[
                      'Address Line 1',
                      'Address Line 2',
                      'Town/City',
                      'County',
                      'Postcode',
                      'Billing',
                      'Actions',
                    ]}
                    rowData={visibleRows}
                    deletable={{
                      deletedRowData: deletedRows,
                      confirmDelete: async () => {
                        const pluralize =
                          deletedRowIds.length > 1 ? 'addresses' : 'address';
                        try {
                          await deleteAddresses({
                            variables: {
                              ids: deletedRowIds,
                            },
                            refetchQueries: [{query: QUERY_USER_ADDRESSES}],
                          });
                          addNotification({
                            type: 'success',
                            message: `Successfully deleted ${pluralize}`,
                          });
                        } catch (ex) {
                          addNotification({
                            type: 'danger',
                            message: `Failed to delete ${pluralize}: ${ex.message}`,
                          });
                        }
                      },
                      undoDelete: clearDeletedRows,
                    }}
                    rowRenderer={({
                      id,
                      addressLine1,
                      addressLine2,
                      townOrCity,
                      county,
                      postcode,
                      billing,
                    }) => (
                      <tr key={id}>
                        <Td value={addressLine1} />
                        <Td value={addressLine2} />
                        <Td value={townOrCity} />
                        <Td value={county} />
                        <Td value={postcode} />
                        <Td value={billing ? 'Yes' : 'No'} />
                        <Td>
                          <Button
                            icon="faTrashAlt"
                            danger
                            ariaLabel="Delete address"
                            title="Delete address"
                            small
                            handleClick={() => deleteRow(id)}
                          />
                        </Td>
                      </tr>
                    )}
                  >
                    <InputTr>
                      <td>
                        <Input
                          id="addressLine1"
                          value={values.addressLine1}
                          label="Address Line 1"
                        />
                      </td>
                      <td>
                        <Input
                          id="addressLine2"
                          value={values.addressLine2}
                          label="Address Line 2"
                        />
                      </td>
                      <td>
                        <Input
                          id="townOrCity"
                          value={values.townOrCity}
                          label="Town/City"
                        />
                      </td>
                      <td>
                        <Input id="county" value={values.county} label="County" />
                      </td>
                      <td>
                        <Input id="postcode" value={values.postcode} label="Postcode" />
                      </td>
                      <td>
                        <Checkbox
                          checked={values.billing}
                          id="billing"
                          value="billing"
                          label="Billing"
                        />
                      </td>
                      <td>
                        <Button
                          disabled={isSubmitting}
                          icon="faSave"
                          primary
                          ariaLabel="Add address"
                          title="Add address"
                          small
                          type="submit"
                        />
                      </td>
                    </InputTr>
                  </Table>
                </Form>
              </Card>
            );
          }}
        </Formik>
      </div>
    </Row>
  );
};

AddressTable.propTypes = {
  addNotification: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      addressLine1: PropTypes.string.isRequired,
      addressLine2: PropTypes.string,
      townOrCity: PropTypes.string.isRequired,
      county: PropTypes.string.isRequired,
      postcode: PropTypes.string.isRequired,
      billing: PropTypes.bool.isRequired,
    })
  ),
};

export default connect(null, {
  addNotification: actions.addNotification,
})(AddressTable);
