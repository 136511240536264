import React from 'react';
import {Formik} from 'formik';
import map from 'lodash/map';
import {useQuery, useMutation} from '@apollo/react-hooks';
import Card from 'components/blocks/card';
import {Form} from 'components/wrappers';
import Input from 'components/formik/input';
import Textarea from 'components/formik/textarea';
import Button from 'components/button/button';
import {profileSchema} from 'utils/validation';
import {QUERY_USER, MUTATION_UPDATE_USER} from 'components/app/profile/profile-gql';
import Badges from 'components/blocks/badges';
import {connect} from 'react-redux';
import {actions} from 'state/notifications';
import PropTypes from 'prop-types';

const PersonalDetails = ({addNotification}) => {
  const {loading, error, data} = useQuery(QUERY_USER);
  const [updateUser] = useMutation(MUTATION_UPDATE_USER);

  if (error) return <p>{error.message}</p>;
  if (loading) return <p>Loading</p>;

  const {
    firstName,
    lastName,
    nickname,
    email,
    id,
    roles,
    profile: {bio},
  } = data.user;

  return (
    <Card title="Personal Details">
      <Badges badges={map(roles, role => role.name)} />
      <Formik
        initialValues={{
          firstName,
          lastName,
          nickname,
          email,
          bio,
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);

          try {
            await updateUser({
              variables: {
                userInput: {
                  id,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  nickname: values.nickname,
                  bio: values.bio,
                },
              },
            });
            addNotification({
              type: 'success',
              message: 'Successfully updated personal details.',
            });
          } catch (ex) {
            addNotification({
              type: 'danger',
              message: `Failed to update personal details: ${ex.message}`,
            });
          }
          actions.setSubmitting(false);
        }}
        validationSchema={profileSchema}
      >
        {props => {
          const {values, handleSubmit, isSubmitting} = props;
          const {firstName, lastName, nickname, email, bio} = values;

          return (
            <Form onSubmit={handleSubmit}>
              <Input
                id="nickname"
                label="Nickname"
                value={nickname}
                disabled={isSubmitting}
              />
              <Input
                id="firstName"
                label="First name"
                value={firstName}
                disabled={isSubmitting}
              />
              <Input
                id="lastName"
                label="Last name"
                value={lastName}
                disabled={isSubmitting}
              />
              <Textarea id="bio" label="Bio" value={bio} disabled={isSubmitting} />
              <Input id="email" label="Email" type="email" value={email} readonly />
              <Button type="submit" primary>
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

PersonalDetails.propTypes = {
  addNotification: PropTypes.func.isRequired,
};

export default connect(null, {
  addNotification: actions.addNotification,
})(PersonalDetails);
