import React from 'react';
import styled, {css} from 'styled-components';
import {IconTI} from 'components/icons';

const Root = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;

  i {
    ${props =>
      props.visible &&
      css`
        transform: rotate(90deg);
      `}
  }
`;

export const ToggleIcon = ({label, visible, fontSize}) => (
  <Root visible={visible}>
    {label} <IconTI icon="angle-right" fontSize={fontSize} />
  </Root>
);
