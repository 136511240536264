import React from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'gatsby';
import map from 'lodash/map';
import Logo from 'components/images/logo';
import {useToggle} from 'utils/hooks';
import {getLocation} from 'utils';
import {ToggleIcon} from 'components/toggle';
import {getAppRouteFullPath} from 'utils/constant';

// const apps = {
//
// };

const sideBarItems = {
  Dashboard: getAppRouteFullPath('dashboard'),
  'Demo Apps': {
    visible: true,
    links: {
      'Pomodoro Timer': getAppRouteFullPath('pomodoroTimer'),
      'Dividend Voucher': getAppRouteFullPath('dividendVoucher'),
      'Take Home Pay Calculator': getAppRouteFullPath('takeHomePayCalculator'),
      'Test (API Data)': getAppRouteFullPath('test'),
    },
  },
  Help: getAppRouteFullPath('help'),
};

const Root = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  background-color: ${props => props.theme.colors.sideBar};
  white-space: nowrap;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  z-index: 997;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.3s;
  ${props =>
    props.visible &&
    css`
      visibility: visible;
      transition: transform 0.3s;
      transform: translate3d(0, 0, 0);
    `}
`;
const Header = styled.header`
  background: ${props => props.theme.colors.link};
  padding: 0 12px;
  display: flex;
  align-items: center;
  color: #fff;
  height: 64px;
`;
const Nav = styled.nav`
  position: relative;
  overflow: hidden;
  flex: 1 1;
  touch-action: auto;
`;
const Menu = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
`;
const MenuItem = styled.li`
  vertical-align: top;
  transition: opacity 0.2s linear;
`;

const cssFirstLevelLinkStyles = css`
  height: 56px;
  padding: 0 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  transition: 0.2s linear;
  position: relative;
  color: #fff;
  background: transparent;
  border: 0;
  width: 100%;
  text-align: left;

  ${props => props.theme.hoverSidebar}
  ${props => props.theme.focusOutlineSideBar}

  ${props =>
    props['aria-selected'] &&
    css`
      font-weight: bold;
    `}
`;
const MenuToggle = styled.button`
  ${cssFirstLevelLinkStyles}
`;
const MenuLink = styled(Link)`
  ${cssFirstLevelLinkStyles}
`;

const SubMenu = styled.ul`
  background-color: ${props => props.theme.colors.sideBarSubMenu};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
  display: none;
  list-style-type: none;
  margin-bottom: 0;

  ${props =>
    props.visible &&
    css`
      display: block;
    `}
`;

const SubMenuLink = styled(props => <Link {...props} />)`
  height: 32px;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0;
  color: #fff;
  opacity: 0.8;
  transition: 0.2s linear;
  align-items: center;
  display: flex;
  padding: 0 12px;

  ${props => props.theme.hoverSidebar}
  ${props => props.theme.focusOutlineSideBar}

  span {
    font-weight: bold;
  }
`;

const SideBarMenuItem = ({
  toggleLabel,
  subMenuLinks,
  pathname,
  visible: initialVisibility,
}) => {
  const {visible, handleToggle} = useToggle(initialVisibility);
  let childLinkIsSelected = false;
  const menuItems = map(subMenuLinks, (to, label) => {
    let txt = label;
    const isSelected = pathname === to;

    if (isSelected) {
      txt = <span>{txt}</span>;
      childLinkIsSelected = true;
    }

    return (
      <SubMenuLink key={to} to={to} aria-selected={isSelected}>
        {txt}
      </SubMenuLink>
    );
  });
  return (
    <MenuItem>
      <MenuToggle
        onClick={handleToggle}
        visible={visible}
        aria-selected={childLinkIsSelected}
      >
        <ToggleIcon fontSize="8px" label={toggleLabel} visible={visible} />
      </MenuToggle>
      <SubMenu visible={visible}>
        <MenuItem>{menuItems}</MenuItem>
      </SubMenu>
    </MenuItem>
  );
};
const SideBar = ({sideBarVisible}) => {
  const {pathname} = getLocation();
  return (
    <Root visible={sideBarVisible}>
      <Header>
        <Logo height={40} />
      </Header>
      <Nav>
        <Menu>
          {map(sideBarItems, (value, key) => {
            if (typeof value === 'string') {
              return (
                <MenuItem key={key}>
                  <MenuLink to={value} aria-selected={value === pathname}>
                    {key}
                  </MenuLink>
                </MenuItem>
              );
            }

            return (
              <SideBarMenuItem
                key={key}
                pathname={pathname}
                toggleLabel={key}
                subMenuLinks={value.links}
                visible={value.visible}
              />
            );
          })}
        </Menu>
      </Nav>
    </Root>
  );
};

export default SideBar;
