import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logo from './logo_sm.png';
import {Link} from 'gatsby';

const Root = styled.img``;
const ImgLink = styled(Link)`
  display: block;
  width: 100%;
  ${props => props.theme.focusOutlineBeige}
`;

const Logo = ({height, to, alt, ariaLabel}) => {
  const img = <Root alt={alt} src={logo} height={height} />;
  if (!to) {
    return img;
  }
  return (
    <ImgLink to={to} aria-label={ariaLabel}>
      {img}
    </ImgLink>
  );
};

Logo.propTypes = {
  height: PropTypes.number.isRequired,
  to: PropTypes.string,
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Logo.defaultProps = {
  height: 92,
  alt: 'BearJS',
  ariaLabel: 'Dashboard',
  to: '/app',
};

export default Logo;
