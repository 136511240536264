import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Copyright from 'components/copyright';

const Root = styled.footer`
  background-color: #fff;
  font-size: 12px;
  color: #616a78;
  border-top: 1px solid #f1f2f3;
  padding: 15px 30px;

  p {
    margin: 0;
  }
`;

const Footer = ({isStaticPage}) => {
  return (
    <Root>
      <div className={isStaticPage ? 'container' : ''}>
        <div className="row">
          <div className="col-md-6">
            <p className="text-center text-md-left">
              <Copyright />
            </p>
          </div>
          <div className="col-md-6">
            <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
              <li className="nav-item">
                <a className="nav-link" href="../help/articles.html">
                  Documentation
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="../help/faq.html">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Root>
  );
};

Footer.propTypes = {
  isStaticPage: PropTypes.bool,
};

export default Footer;
