import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import orderBy from 'lodash/orderBy';
import {getIndexModifierClass} from 'utils/constant';

const Root = styled.div`
  margin-bottom: 30px;

  .badge {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
const Badges = ({badges, pill}) => {
  if (badges && badges.length) {
    let otherClassNames = '';

    if (pill) {
      otherClassNames = `${otherClassNames} badge-pill`;
    }

    const ordered = orderBy(badges, null, 'asc');

    return (
      <Root>
        {ordered.map((badge, index) => (
          <span
            className={getIndexModifierClass('badge', index, otherClassNames)}
            key={badge}
          >
            {startCase(lowerCase(badge))}
          </span>
        ))}
      </Root>
    );
  }
  return null;
};

Badges.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.string),
  pill: PropTypes.bool,
};

export default Badges;
