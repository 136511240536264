import sample from 'lodash/sample';

export const basePath = '/app';
export const appRoutes = {
  dashboard: '/',
  help: '/help',
  test: '/test',
  dividendVoucher: '/dividend-voucher',
  takeHomePayCalculator: '/take-home-pay-calculator',
  pomodoroTimer: '/pomodoro',
  profile: '/profile',
};

export const getAppRouteFullPath = route => `${basePath}${appRoutes[route]}`;

export const bootstrapModifierClasses = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark',
];

const len = bootstrapModifierClasses.length;

export const getRandomModifierClass = (baseClassName, otherClassNames) => {
  const random = sample(bootstrapModifierClasses);
  if (!baseClassName) {
    return random;
  }
  return `${baseClassName} ${baseClassName}-${random} ${otherClassNames}`;
};

export const getIndexModifierClass = (baseClassName, index, otherClassNames) => {
  const random =
    index < len ? bootstrapModifierClasses[index] : sample(bootstrapModifierClasses);

  if (!baseClassName) {
    return random;
  }
  return `${baseClassName} ${baseClassName}-${random} ${otherClassNames}`;
};
