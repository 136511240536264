import React from 'react';
import styled, {css} from 'styled-components';
import {useOffClickEffect} from 'utils/hooks';
import avatar from 'assets/img/avatar.jpg';
import Icon from 'components/icons';
import {NavButton} from 'components/nav/app-navbar';
import auth from 'utils/auth';
import {Link} from 'gatsby';
import {getAppRouteFullPath} from 'utils/constant';

const menuItemCSS = css`
  color: #60666f;
  font-weight: 300;
  padding: 4px 12px;
  width: auto;
  margin: 4px;
  transition: 0.15s linear;
  display: block;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none !important;
  border: none;
  outline-width: 0;
`;

const MenuItem = styled.a`
  ${menuItemCSS}
`;

const MenuItemRouter = styled(props => <Link {...props} />)`
  ${menuItemCSS}
`;
const UserMenuRoot = styled.li`
  flex-shrink: 0;
  position: relative;
  padding-left: 0;
  margin-bottom: calc(1.75rem / 2);
  list-style: none;
`;
const Menu = styled.ul`
    display: none;
    position: absolute;
    will-change: top, left;
    top: 65px;
    left: -120px;
    min-width: 180px;
    max-width: 360px;
    border: 1px solid rgba(235,235,235,0.4);
    border-top: 0;
    line-height: 1.625rem;
    margin-top: 7px !important;
    overflow: visible !important;
    color: #4d5259;
    font-size: 13px;
    padding: 0;
    box-shadow: 0 0 4px rgba(0,0,0,0.06);
    z-index: 992;
    right: 0;
    float: left;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: -11px;
      left: 17px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 9px solid rgba(0,0,0,0.045);
    }
    
    &::after{
      top: -9px;
      border-bottom-color: #fdfeff;
    }
    
    ${props =>
      props.menuRight &&
      css`
        &::before {
          left: auto;
          right: 17px;
        }

        &::after {
          left: auto;
          right: 17px;
        }
      `}
    
    ${props =>
      props.visible &&
      css`
        display: block;
      `}     
  }
`;

const Item = ({label, icon, handleClick, to}) => {
  if (to) {
    return (
      <li>
        <MenuItemRouter to={to}>
          <Icon icon={icon} />
          {` ${label}`}
        </MenuItemRouter>
      </li>
    );
  }
  return (
    <li>
      <MenuItem href="#" role="menuitem" onClick={handleClick}>
        <Icon icon={icon} />
        {` ${label}`}
      </MenuItem>
    </li>
  );
};

const UserMenu = () => {
  const {visible, handleToggle, toggleAlt} = useOffClickEffect(false);
  const isLoggedIn = auth.isAuthenticated();
  const {picture} = auth.profile;
  const alt = `${toggleAlt} user menu`;
  return (
    <UserMenuRoot>
      <NavButton
        group
        ariaHasPopup
        ariaExpanded={visible}
        ariaLabel={alt}
        handleClick={handleToggle}
        title={alt}
      >
        <img
          className="avatar"
          src={isLoggedIn && picture ? picture : avatar}
          alt="User menu"
        />
      </NavButton>
      <Menu visible={visible} menuRight role="menu">
        <Item to={getAppRouteFullPath('profile')} icon="user" label="Profile" />
        <Item icon="settings" label="Settings" />
        <div className="dropdown-divider" />
        <Item icon="faSignOutAlt" label="Logout" handleClick={auth.logout} />
      </Menu>
    </UserMenuRoot>
  );
};

export default UserMenu;
