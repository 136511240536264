import React from 'react';
import {Formik} from 'formik';
import {useLocalStorage} from 'utils/hooks';
import {useMutation} from '@apollo/react-hooks';
import map from 'lodash/map';
import trim from 'lodash/trim';
import {getISODateString} from 'utils/date';
import {Form, Row} from 'components/wrappers';
import Card from 'components/blocks/card';
import Input from 'components/formik/input';
import Checkbox from 'components/formik/check-input';
import Button from 'components/button/button';
import {
  MUTATION_CREATE_DIVIDEND_VOUCHER,
  QUERY_DIVIDEND_VOUCHERS,
} from 'components/app/dividend_voucher/dividend-voucher-gql';
import validators, {
  currencyAmountValidator,
  dateValidator,
  integerValidator,
  shape,
  stringValidator,
} from 'utils/validation';

const dummyValues = {
  shareholder: 'Bob the Builder',
  company: 'No Such Company Ltd',
  isFinal: true,
  companyYearEndDate: '28/02/2021',
  meetingAndDeclarationDate: '10/01/2020',
  numberOfSharesOwned: 100,
  meetingLocation: '29 No Such Place, Milton Keynes, MK14 5FT',
  dividendPerShare: 50,
  meetingAttendees: 'Bob the Builder, Mr. Blobby',
};

const getPlaceholder = key => `i.e. ${dummyValues[key]}`;

const validationSchema = shape({
  company: validators.company,
  companyYearEndDate: dateValidator('Company year end date'),
  meetingAndDeclarationDate: dateValidator('Company year end date'),
  meetingAttendees: stringValidator('Meeting attendees'),
  meetingLocation: stringValidator('Meeting location'),
  shareholder: stringValidator('Shareholder'),
  numberOfSharesOwned: integerValidator('Number of shares owned'),
  dividendPerShare: currencyAmountValidator('Dividend per share'),
});

const DividendVoucherForm = () => {
  const {item, handleSetItemState} = useLocalStorage('CONTRACTOR-DIVIDEND_VOUCHER');
  const [createDividendVoucher] = useMutation(MUTATION_CREATE_DIVIDEND_VOUCHER);
  return (
    <Formik
      initialValues={item || dummyValues}
      onSubmit={async (values, actions) => {
        const {setSubmitting, setStatus} = actions;

        setSubmitting(true);
        handleSetItemState(values);

        const {
          shareholder,
          company,
          isFinal,
          dividendPerShare,
          numberOfSharesOwned,
          meetingAndDeclarationDate,
          companyYearEndDate,
          meetingAttendees,
          meetingLocation,
        } = values;

        const dividendVoucherCreateInput = {
          company,
          shareholder,
          isFinal,
          dividendPerShare: parseFloat(dividendPerShare, 10),
          numberOfSharesOwned: parseInt(numberOfSharesOwned, 10),
          meetingAndDeclarationDate: getISODateString(meetingAndDeclarationDate),
          companyYearEndDate: getISODateString(companyYearEndDate),
          meetingAttendees: map(meetingAttendees.split(','), str => trim(str)),
          meetingLocation,
        };
        try {
          await createDividendVoucher({
            variables: {
              dividendVoucherCreateInput,
            },
            refetchQueries: [{query: QUERY_DIVIDEND_VOUCHERS}],
          });
          actions.setSubmitting(false);
        } catch (ex) {
          setStatus({
            message: ex.message,
            type: 'error',
          });
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {props => {
        const {values, handleSubmit, isSubmitting} = props;
        const {
          shareholder,
          company,
          meetingAndDeclarationDate,
          meetingAttendees,
          meetingLocation,
          isFinal,
          dividendPerShare,
          companyYearEndDate,
          numberOfSharesOwned,
        } = values;

        return (
          <Row>
            <div className="col-lg-5">
              <Card title="Dividend Details">
                <p>
                  Please enter your company details in the form below to populate the
                  board meeting notes and dividend voucher to the right.
                </p>
                <Form onSubmit={handleSubmit}>
                  <Input
                    id="company"
                    label="Company"
                    value={company}
                    placeholder={getPlaceholder('company')}
                  />
                  <Input
                    id="companyYearEndDate"
                    label="Company year end date"
                    value={companyYearEndDate}
                    placeholder="DD/MM/YYYY"
                  />
                  <Input
                    id="meetingAndDeclarationDate"
                    label="Meeting/declaration date"
                    value={meetingAndDeclarationDate}
                    placeholder="DD/MM/YYYY"
                  />
                  <Input
                    id="meetingAttendees"
                    label="Meeting attendees"
                    value={meetingAttendees}
                    placeholder={getPlaceholder('meetingAttendees')}
                  />
                  <Input
                    id="meetingLocation"
                    label="Meeting location"
                    value={meetingLocation}
                    placeholder={getPlaceholder('meetingLocation')}
                  />
                  <Checkbox
                    checked={isFinal}
                    id="isFinal"
                    value="isFinal"
                    label="Final Dividend"
                  />
                  <Input
                    id="shareholder"
                    label="Shareholder"
                    value={shareholder}
                    placeholder={getPlaceholder('shareholder')}
                  />
                  <Input
                    id="numberOfSharesOwned"
                    label="Number of shares owned"
                    value={numberOfSharesOwned}
                    placeholder={getPlaceholder('numberOfSharesOwned')}
                  />
                  <Input
                    id="dividendPerShare"
                    label="Dividend per share"
                    value={dividendPerShare}
                    placeholder={getPlaceholder('dividendPerShare')}
                  />
                  <p>
                    Please read through you are happy with the voucher in the `Preview`
                    section and hit the `Generate Dividend Voucher` button below to
                    continue.
                  </p>
                  <Button
                    type="submit"
                    primary
                    className="float-right"
                    disabled={isSubmitting}
                  >
                    Generate Dividend Voucher
                  </Button>
                </Form>
              </Card>
            </div>
            <div className="col-lg-7">
              <Card title="Preview">
                <h3>{company}</h3>
                <h4>Board meeting minutes</h4>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row">Meeting date</th>
                      <td>{meetingAndDeclarationDate}</td>
                    </tr>
                    <tr>
                      <th scope="row">Meeting location</th>
                      <td>{meetingLocation}</td>
                    </tr>
                    <tr>
                      <th scope="row">Meeting attendees</th>
                      <td>{meetingAttendees}</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  It was resolved that the Company (<strong>{company}</strong>) pay
                  <strong>{isFinal ? ' a final ' : ' an interim '}</strong>
                  dividend of <strong>£{dividendPerShare}</strong> per £1 Ordinary Share
                  in respect of the year ended <strong>{companyYearEndDate}</strong> to
                  those shareholders registered at the close of business on{' '}
                  <strong>{meetingAndDeclarationDate}</strong>.
                </p>
                <p>There are no further business, the meeting was closed.</p>
                <h4>Dividend Voucher</h4>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row">Company</th>
                      <td>{company}</td>
                    </tr>
                    <tr>
                      <th scope="row">Shareholder</th>
                      <td>{shareholder}</td>
                    </tr>
                    <tr>
                      <th scope="row">No. ordinary shares owned</th>
                      <td>{numberOfSharesOwned}</td>
                    </tr>
                    <tr>
                      <th scope="row">Dividend per share</th>
                      <td>£{dividendPerShare}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total dividend</th>
                      <td>£{dividendPerShare * numberOfSharesOwned}</td>
                    </tr>
                    <tr>
                      <th scope="row">Declaration date</th>
                      <td>{meetingAndDeclarationDate}</td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </Row>
        );
      }}
    </Formik>
  );
};

export default DividendVoucherForm;
