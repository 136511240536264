import React from 'react';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {useDeleteTableRows} from 'utils/hooks';
import {Row} from 'components/wrappers';
import Card from 'components/blocks/card';
import Table, {Td} from 'components/table';
import Button, {ButtonGroup} from 'components/button/button';
import {
  MUTATION_DELETE_DIVIDEND_VOUCHERS,
  QUERY_DIVIDEND_VOUCHERS,
} from 'components/app/dividend_voucher/dividend-voucher-gql';

const DividendVouchersTable = () => {
  const {loading, error, data} = useQuery(QUERY_DIVIDEND_VOUCHERS);
  const [deleteDividendVouchers] = useMutation(MUTATION_DELETE_DIVIDEND_VOUCHERS);
  const {
    deletedRowIds,
    deleteRow,
    getVisibleAndDeletedRows,
    clearDeletedRows,
  } = useDeleteTableRows([]);
  if (error) return <p>{error.message}</p>;
  if (loading) return <p>Loading</p>;
  const {dividendVouchers} = data;

  if (!(dividendVouchers && dividendVouchers.length)) {
    return null;
  }

  const {deletedRows, visibleRows} = getVisibleAndDeletedRows(dividendVouchers);
  return (
    <Row>
      <div className="col-lg-12">
        <Card title="Dividend Vouchers">
          <Table
            className="table-bordered table-striped table-hover"
            thead={[
              'Created at',
              'Company',
              'Company year end date',
              'Meeting and declaration date',
              'Meeting attendees',
              'Meeting location',
              'Interim or final',
              'Shareholder',
              'No. shares owned',
              'Dividend per share',
              'Actions',
            ]}
            rowData={visibleRows}
            deletable={{
              deletedRowData: deletedRows,
              confirmDelete: async () => {
                try {
                  await deleteDividendVouchers({
                    variables: {
                      ids: deletedRowIds,
                    },
                    refetchQueries: [{query: QUERY_DIVIDEND_VOUCHERS}],
                  });
                } catch (ex) {
                  console.error(ex);
                }
              },
              undoDelete: clearDeletedRows,
            }}
            rowRenderer={({
              id,
              createdAt,
              company,
              companyYearEndDate,
              meetingAndDeclarationDate,
              meetingAttendees,
              meetingLocation,
              isFinal,
              dividendPerShare,
              shareholder,
              numberOfSharesOwned,
            }) => (
              <tr key={id}>
                <Td value={createdAt} format="datetime" />
                <Td value={company} />
                <Td value={companyYearEndDate} format="date" />
                <Td value={meetingAndDeclarationDate} format="date" />
                <Td value={meetingAttendees} format="array:comma" />
                <Td value={meetingLocation} />
                <Td value={isFinal ? 'Final' : 'Interim'} />
                <Td value={shareholder} />
                <Td value={numberOfSharesOwned} />
                <Td value={dividendPerShare} format="currency" />
                <Td>
                  <ButtonGroup>
                    <Button
                      icon="faEye"
                      primary
                      ariaLabel="View dividend voucher"
                      title="View dividend voucher"
                      small
                    />
                    <Button
                      icon="faTrashAlt"
                      danger
                      ariaLabel="Delete dividend"
                      title="Delete dividend"
                      small
                      handleClick={() => deleteRow(id)}
                    />
                  </ButtonGroup>
                </Td>
              </tr>
            )}
          />
        </Card>
      </div>
    </Row>
  );
};

export default DividendVouchersTable;
