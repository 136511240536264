import React from 'react';
import styled from 'styled-components';
import img from 'assets/img/bg_general.png';
import PropTypes from 'prop-types';

const Root = styled.header`
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  border-bottom: 1px solid #ebebeb;
  background-image: url(${props => props.bgImg || img});
`;

const HeaderInfo = styled.div`
  margin: 50px 0;
  padding: 0 30px;
  width: 100%;

  h1 {
    font-weight: 100;
    font-family: Roboto, sans-serif;
    color: #4d5259;
  }

  p {
    display: block;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.7;
    color: inherit;
    opacity: 0.7;
    padding-top: 16px;
    margin-bottom: 0;
  }
`;
const Header = props => {
  const {h1, intro, bgImg} = props;
  return (
    <Root bgImg={bgImg}>
      <HeaderInfo>
        <h1>{h1}</h1>
        {intro && intro.map(txt => <p key={txt}>{txt}</p>)}
      </HeaderInfo>
    </Root>
  );
};

Header.propTypes = {
  h1: PropTypes.string.isRequired,
  intro: PropTypes.arrayOf(PropTypes.string),
  bgImg: PropTypes.string.isRequired,
};

export default Header;
