import React, {Fragment} from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Card from 'components/blocks/card';

const QUERY_GENERAL = gql`
  query {
    hello

    whatsForDinner

    events {
      id
      title
      date
      description
      attendants {
        name
        email
      }
    }
  }
`;

const QUERY_EVENT = gql`
  query($id: Int!) {
    event(id: $id) {
      title
      date
      attendants {
        name
      }
    }
  }
`;

const Test = () => {
  const {loading, error, data} = useQuery(QUERY_GENERAL);
  if (error) return <p>{error.message}</p>;
  if (loading) return <p>Loading</p>;

  const {hello, whatsForDinner, events} = data;

  return (
    <Fragment>
      <Card title={hello}>
        <p>{whatsForDinner}</p>
      </Card>
      <Card title="Events">
        {events.map(({id, title, date, description, attendants}, i) => (
          <div key={id}>
            {i !== 0 && <hr />}
            <h3>{title}</h3>
            <p>{date}</p>
            <p>{description}</p>
            <h4>Attendants</h4>
            <ul>
              {attendants.map(({name}) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </div>
        ))}
      </Card>
      <Card title="Raw Query Data">
        <pre>{JSON.stringify(data, null, 4)}</pre>
      </Card>
    </Fragment>
  );
};

Test.layoutHeader = {
  h1: 'Test',
  intro: ['Test new features'],
};

export default Test;
