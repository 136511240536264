import React from 'react';
import PropTypes from 'prop-types';
import Table, {Td} from 'components/table';
import Button from 'components/button/button';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {useDeleteTableRows} from 'utils/hooks';
import gql from 'graphql-tag';
import Card from 'components/blocks/card';
import {connect} from 'react-redux';
import {actions} from 'state/notifications';

export const QUERY_ENQUIRIES = gql`
  query {
    enquiries {
      id
      firstName
      lastName
      email
      message
      company
      createdAt
    }
  }
`;

export const MUTATION_DELETE_ENQUIRIES = gql`
  mutation DeleteEnquiries($ids: [ID!]!) {
    deleteEnquiries(ids: $ids) {
      message
    }
  }
`;

const Enquiries = ({addNotification}) => {
  const {loading, error, data} = useQuery(QUERY_ENQUIRIES);
  const [deleteEnquiries] = useMutation(MUTATION_DELETE_ENQUIRIES);
  const {
    deletedRowIds,
    deleteRow,
    getVisibleAndDeletedRows,
    clearDeletedRows,
  } = useDeleteTableRows([]);

  if (error) return <p>{error.message}</p>;
  if (loading) return <p>Loading</p>;

  const {enquiries} = data;
  if (!(enquiries && enquiries.length)) {
    return null;
  }

  const {deletedRows, visibleRows} = getVisibleAndDeletedRows(enquiries);

  return (
    <Card title="Enquiries">
      <Table
        className="table-bordered table-striped table-hover"
        thead={[
          'Date',
          'First name',
          'Last name',
          'Email',
          'Company',
          'Message',
          'Actions',
        ]}
        rowData={visibleRows}
        deletable={{
          deletedRowData: deletedRows,
          confirmDelete: async () => {
            const pluralize = deletedRowIds.length > 1 ? 'enquiries' : 'enquiry';
            try {
              await deleteEnquiries({
                variables: {
                  ids: deletedRowIds,
                },
                refetchQueries: [{query: QUERY_ENQUIRIES}],
              });
              addNotification({
                type: 'success',
                message: `Successfully deleted ${pluralize}`,
              });
            } catch (ex) {
              addNotification({
                type: 'danger',
                message: `Failed to delete ${pluralize}: ${ex.message}`,
              });
            }
          },
          undoDelete: clearDeletedRows,
        }}
        rowRenderer={({id, createdAt, firstName, lastName, email, company, message}) => (
          <tr key={id}>
            <Td value={createdAt} />
            <Td value={firstName} />
            <Td value={lastName} />
            <Td value={email} />
            <Td value={company} />
            <Td value={message} />
            <Td>
              <Button
                icon="faTrashAlt"
                danger
                ariaLabel="Delete enquiry"
                title="Delete enquiry"
                small
                handleClick={() => deleteRow(id)}
              />
            </Td>
          </tr>
        )}
      />
    </Card>
  );
};

Enquiries.propTypes = {
  addNotification: PropTypes.func.isRequired,
};

export default connect(null, {
  addNotification: actions.addNotification,
})(Enquiries);
