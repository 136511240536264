import React from 'react';

const TakeHomePayCalculator = () => <div />;

TakeHomePayCalculator.layoutHeader = {
  h1: 'Take Home Pay Calculator',
  intro: ['Calculate your take home pay based on your day rate.'],
};

export default TakeHomePayCalculator;
