import React from 'react';

const Help = () => <div />;

Help.layoutHeader = {
  h1: 'Help',
  intro: ['All your questions about the app answered.'],
};

export default Help;
