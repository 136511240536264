import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';
import AppNavbar from 'components/nav/app-navbar';
import SideBar from 'components/sidebar';
import {Root as RootBase, Main} from 'components/layouts/static-layout';
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import theme from 'assets/theme';
import {useToggle} from 'utils/hooks';
import styled, {css} from 'styled-components';
import Notifications from 'components/notifications/notifications';

const Root = styled(RootBase)`
  ${props =>
    props.sideBarVisible &&
    css`
      margin-left: 260px;
    `}
`;
const Content = styled.div`
  padding: 30px 30px 0;
`;

const AppLayout = ({location, children, header}) => {
  const {
    visible: sideBarVisible,
    handleToggle: toggleSideBar,
    toggleAlt: sideBarToggleAlt,
  } = useToggle(true);

  return (
    <ThemeProvider theme={theme}>
      <SideBar sideBarVisible={sideBarVisible} />
      <Root sideBarVisible={sideBarVisible}>
        <AppNavbar
          {...{
            sideBarVisible,
            toggleSideBar,
            sideBarToggleAlt,
            location,
          }}
        />
        <Notifications />
        <Main>
          {header && <Header {...header} />}
          <Content>{children}</Content>
        </Main>
        <Footer />
      </Root>
    </ThemeProvider>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  header: PropTypes.shape({
    h1: PropTypes.string.isRequired,
    intro: PropTypes.arrayOf(PropTypes.string),
    bgImg: PropTypes.string,
  }),
};

export default AppLayout;
