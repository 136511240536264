import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import auth from 'utils/auth';
import AppLayout from 'components/layouts/app-layout';

const PrivateRoute = ({component: Component, location, ...rest}) => {
  if (!auth.isAuthenticated()) {
    auth.login();
    return (
      <Fragment>
        <p>Please Login to access the app.</p>
      </Fragment>
    );
  }

  return (
    <AppLayout header={Component.layoutHeader} location={location}>
      <Component {...rest} />
    </AppLayout>
  );
};

PrivateRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
