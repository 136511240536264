import gql from 'graphql-tag';

export const MUTATION_CREATE_ADDRESS = gql`
  mutation CreateDividendVoucher($addressCreateInput: AddressCreateInput!) {
    createAddress(addressCreateInput: $addressCreateInput) {
      id
    }
  }
`;

export const MUTATION_DELETE_ADDRESS = gql`
  mutation DeleteAddresses($ids: [ID!]!) {
    deleteAddresses(ids: $ids) {
      message
    }
  }
`;

export const QUERY_USER_ADDRESSES = gql`
  query {
    user {
      id
      addresses {
        id
        addressLine1
        addressLine2
        townOrCity
        county
        billing
        postcode
      }
    }
  }
`;

export const QUERY_USER = gql`
  query {
    user {
      id
      nickname
      name
      firstName
      lastName
      email
      roles {
        id
        name
        description
        permissions
      }
      addresses {
        id
        addressLine1
        addressLine2
        townOrCity
        county
        billing
        postcode
      }
      profile {
        bio
      }
    }
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation UpdateUser($userInput: UserInput!) {
    updateUser(userInput: $userInput) {
      id
      firstName
      lastName
      nickname
      profile {
        bio
      }
    }
  }
`;
