import gql from 'graphql-tag';

export const MUTATION_CREATE_DIVIDEND_VOUCHER = gql`
  mutation CreateDividendVoucher(
    $dividendVoucherCreateInput: DividendVoucherCreateInput!
  ) {
    createDividendVoucher(dividendVoucherCreateInput: $dividendVoucherCreateInput) {
      id
    }
  }
`;

export const MUTATION_DELETE_DIVIDEND_VOUCHERS = gql`
  mutation DeleteDividendVouchers($ids: [ID!]!) {
    deleteDividendVouchers(ids: $ids) {
      message
    }
  }
`;

export const QUERY_DIVIDEND_VOUCHERS = gql`
  query {
    dividendVouchers {
      createdAt
      id
      createdBy {
        id
        email
      }
      shareholder
      company
      meetingAndDeclarationDate
      meetingAttendees
      meetingLocation
      isFinal
      dividendPerShare
      companyYearEndDate
      numberOfSharesOwned
    }
  }
`;
