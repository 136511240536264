import React, {Fragment} from 'react';
import auth from 'utils/auth';
import Card from 'components/blocks/card';
import Enquiries from 'components/app/dashboard/enquries';

const Dashboard = () => {
  const profile = auth.profile;
  return (
    <Fragment>
      <Card title={`Welcome back, ${profile.name}!`}>
        <pre>{JSON.stringify(profile, null, 4)}</pre>
      </Card>
      <Enquiries />
    </Fragment>
  );
};

Dashboard.layoutHeader = {
  h1: 'Dashboard',
  intro: ['Summary of your apps.'],
};

export default Dashboard;
