import React from 'react';
import {connect} from 'formik';
import {Root, getStrings} from 'src/components/form/check-input';
import ErrorMessage from 'src/components/formik/error-message';
import PropTypes from 'prop-types';

const CheckInput = props => {
  const {
    value,
    id,
    name,
    label,
    formik,
    disabled,
    checked,
    type,
    handleChange: onChange,
  } = props;
  const {container, finalName, finalValue} = getStrings({id, name, value, type});

  const handleChange = event => {
    if (onChange) {
      onChange(event);
      return;
    }

    formik.handleChange(event);
  };

  return (
    <Root className={container}>
      <input
        checked={checked}
        name={finalName}
        id={id}
        type={type || 'checkbox'}
        disabled={disabled}
        value={finalValue}
        className="custom-control-input"
        onChange={handleChange}
      />
      <label htmlFor={id} className="custom-control-label">
        {label}
      </label>
      <ErrorMessage errors={formik.errors} touched={formik.touched} name={name} />
    </Root>
  );
};

CheckInput.defaultProps = {
  value: '',
  className: '',
  autoComplete: 'off',
  placeholder: '',
  readonly: false,
  disabled: false,
  checked: false,
  type: 'checkbox',
};

CheckInput.propTypes = {
  handleChange: PropTypes.func,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }),
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default connect(CheckInput);
